<template>
  <div class="flex flex-col text-center">
    <h1 class="text-2xl font-bold">{{$t('models.resources')}}</h1>
    <p>{{$t('info.resources_info')}}</p>
    <div class="mt-4">
      <vimeo-player
          class="video-responsive"
          ref="player"
          :id="545650230"
      ></vimeo-player>
    </div>
  </div>
</template>

<script>
import VimeoPlayer from '../../myCourses/components/UiComponents/VimeoPlayer';

export default {
  name: "Index",
  components: {
    VimeoPlayer,
  }
}
</script>

<style scoped>
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
</style>