<template>
  <div id="player-placement" :aria-label="$t('aria.vimeo')"></div>
</template>

<script>
import Player from '@vimeo/player';
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      player: undefined
    };
  },

  methods: {
    install(that) {
      let p = new Player('player-placement', {
        url: `https://player.vimeo.com/video/${this.id}`,
        options: { responsive: true, controls: false }
      });
      p.on('ended', function(event) {
        that.$emit('end', event);
      });

      that.player = p;
    },

    load(id) {
      const options = { url: `https://player.vimeo.com/video/${id}` };
      this.player.loadVideo(options);
    }
  },

  mounted() {
    this.install(this);
  }
};
</script>

<style>
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
